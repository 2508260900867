"use client";
import { useUserData } from "../../hooks/useUserData";
import { marketingNavLinks, membersNavLinks, navLinks } from "./links";
import { NavLink } from "./NavLink";

export function NavList({ t }) {
  const { data: user, isLoading } = useUserData();
  return (
    <>
      {!isLoading &&
        user.isLoggedIn &&
        membersNavLinks.map((link) => {
          return <NavLink key={link.slug} slug={link.slug} name={t[link.id]} />;
        })}
      {navLinks.map((link) => {
        return <NavLink key={link.slug} slug={link.slug} name={t[link.id]} />;
      })}
      {!isLoading &&
        !user.isLoggedIn &&
        marketingNavLinks.map((link) => {
          return (
            <li key={link.id}>
              <details id="navbar-submenu-about">
                <summary className="text-primary">{t[link.id]}</summary>
                <ul className="z-50 min-w-max border bg-neutral p-2 shadow-none">
                  {link.children.map((childLink) => {
                    return (
                      <NavLink
                        key={childLink.slug}
                        slug={childLink.slug}
                        name={t[childLink.id]}
                      />
                    );
                  })}
                </ul>
              </details>
            </li>
          );
        })}
    </>
  );
}
