"use client";

import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { User } from "@/app/types";
import { getUserMeta } from "@/app/lib/getUserMeta";

/**
 * Custom hook to fetch and cache authenticated user data.
 * @returns {UseQueryResult<User, Error>}
 */
export function useUserData(): UseQueryResult<User, Error> {
  return useQuery<User, Error>({
    queryKey: ["getUserMeta"], // queryKey
    queryFn: getUserMeta, // queryFn
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 1, // Retry once on failure
    refetchOnWindowFocus: false, // Disable refetch on window focus
  });
}
