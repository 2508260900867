import {
  ABOUT_LINK,
  BLOG_LINK,
  CLUB_LINK,
  COMMUNITY_LINK,
  CONTACT_LINK,
  EVENTS_LINK,
  GUIDES_LINK,
  HOME_LINK,
  IMPRINT_LINK,
  NEIGHBORHOOD_LINK,
  PRIVATE_LINK,
  PERKS_LINK,
  TIMELINE_LINK,
  CHAT_URL,
} from "@/app/constants";

type LinkType = {
  slug?: string;
  id: string;
  children?: any;
};

export const membersNavLinks: LinkType[] = [
  {
    slug: CHAT_URL,
    id: "chat",
  },
];

export const navLinks: LinkType[] = [
  {
    slug: TIMELINE_LINK,
    id: "timeline",
  },
  {
    slug: BLOG_LINK,
    id: "blog",
  },
  {
    slug: EVENTS_LINK,
    id: "events",
  },
  {
    slug: GUIDES_LINK,
    id: "guides",
  },
  {
    slug: PERKS_LINK,
    id: "perks",
  },
  {
    slug: NEIGHBORHOOD_LINK,
    id: "neighborhood",
  },
];

export const marketingNavLinks: LinkType[] = [
  {
    id: "about",
    children: [
      {
        slug: ABOUT_LINK,
        id: "us",
      },
      {
        slug: CLUB_LINK,
        id: "kitas",
      },
      {
        slug: COMMUNITY_LINK,
        id: "community",
      },
    ],
  },
];

export const footerLinks = {
  company: [
    {
      slug: TIMELINE_LINK,
      id: "timeline",
    },
    {
      slug: BLOG_LINK,
      id: "blog",
    },
    {
      slug: EVENTS_LINK,
      id: "events",
    },
    {
      slug: NEIGHBORHOOD_LINK,
      id: "neighborhood",
    },
    {
      slug: GUIDES_LINK,
      id: "guides",
    },
    {
      slug: PERKS_LINK,
      id: "perks",
    },
  ],
  marketing: [
    {
      id: "home",
      slug: HOME_LINK,
    },
    {
      slug: COMMUNITY_LINK,
      id: "community",
    },
    {
      slug: CLUB_LINK,
      id: "kitas",
    },
    {
      slug: ABOUT_LINK,
      id: "us",
    },
    {
      slug: CONTACT_LINK,
      id: "contact",
    },
  ],
  legal: [
    {
      slug: IMPRINT_LINK,
      id: "imprint",
    },
    {
      slug: PRIVATE_LINK,
      id: "privacy",
    },
  ],
};
