"use client";
import { useAppSelector } from "@/store/hooks";
import { Modal } from "../Modal";
import { useLocale } from "next-intl";
import { usePathname } from "@/navigation";

export function NewsletterModal({ handleClose }: { handleClose?: () => void }) {
  const selectedCategory = useAppSelector((state: any) => state.category);
  const locale = useLocale();
  const pathname = usePathname();
  let modalForm;

  if (selectedCategory === "Kita Search" || pathname.includes("kita-search")) {
    if (locale === "de") {
      // german kita search form
      modalForm =
        "https://b5cb9995.sibforms.com/serve/MUIFAGmpQr4U542XZZQNwH5HYV-_RfGNXxNOa1Y6QRCtoDB5Op8njKkVNajuKaeNJVqV0n4_ciD7_YT30Cwxsr19wdfpEgs5EEOVSbPzq8ss2hqAr_WeiYgYh_18EAcVyD6shSNf93R42EqQIjw81fQTNT7ogMz4C8PTSMOycBoU65OF9FzIw3NmlVmMRAIcoHFTJ1IJ6qhCpTUL";
    } else {
      // english kita search form
      modalForm =
        "https://b5cb9995.sibforms.com/serve/MUIFAOPyMCH_iKKKpdwsdvrfOJf9hJ8FMJlgIhKWAvPgLmmq2aC_zYe5sfd-ZE8DVExc_py-Y-ebMS1eMDSLYzZYEzHTiPQY_LZSf1P2tuVw60JzenML8JWaPKsuY_DTavUhRvWDJ6tkwemSRtcd65hdbB3IRsIFaojkMB5Go60FxZXjRwha88w-UbJvru30wkXfvfR1Izr0HMXH";
    }
  } else if (
    selectedCategory === "Before Your Baby is Born" ||
    pathname.includes("expecting")
  ) {
    // expecting form
    modalForm =
      "https://b5cb9995.sibforms.com/serve/MUIFAHzfpsTET2zc0Uw-zMsUjIbVtSCM_toCWNMsh3h13bH2ZinL2tI-eqeV8gbnu--i4SOb5gezww67Qv-fV0MO9WPDjYu8CC9sm16IRu4ta-RiH2Nk91BBYr9wrfhr5_b_ed1oekoS0Oh0wYAgg20FaVZJokFbHxlyjTQAjqx3lAB_WJTCHWaVEy2IzZwcftkWqgIf7JW-c7wR";
  } else if (pathname.includes("events")) {
    // events form
    modalForm =
      "https://b5cb9995.sibforms.com/serve/MUIFAH3-wMZ9IyitxCctJi4ATnsAnUOnUDN4KuLqQ6xK3rbn6LsnDJi7B-4bxGh6ukaRiIQ-EJOf6IqYNkP2AyCHISgGKtxhgI4FAUbYk3o2MO2dIGfOLPzwHW6VhGe8hxZlkdlbm7p_FyI0LZf7FA-VQq24im1Pw6A5o9eV51A4BY_69Sb_qMRtq5hWZB12S5EtxST0m0pFHQiP";
  } else {
    // multioption form
    modalForm =
      "https://b5cb9995.sibforms.com/serve/MUIFAHEFMB5RRnzDK3r9l0KcbfTgix9c_fC8jwbL6o4Wtzq9MyHSdqrJQeDTk3u7xS34ARDaLbOfISe_0JNW3dmoC_rxqSjUvBJXa6WHXTQ31kiBKRuAUsZ60FtZ-4pwoj-0fEOZv-_OljCXWPhV3DXnbm_QbRg5dyJgtXC-U84O80z1hwYZgOyNTsYVH3SB4TIQjRlnXcCxyRtU";
  }

  return (
    <Modal withCloseButton handleClose={handleClose} modalBoxClasses="p-0">
      <div className="h-[600px]">
        <iframe
          width="100%"
          height="100%"
          src={modalForm}
          allowFullScreen
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
          }}
        />
      </div>
    </Modal>
  );
}
