"use client";

import { useEffect, useState } from "react";
import { P } from "@/app/[locale]/components/shared/tags/TextElements";
import { event } from "@/app/lib/gtag";

const COOKIE = "KTZ-has_accepted_cookie_consent";

export const CookieBanner = ({ t }) => {
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    const hasAccepted = localStorage.getItem(COOKIE);
    if (!hasAccepted) {
      setAccepted(false);
    }
  }, []);

  if (accepted) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-neutral p-2 shadow-lg">
      <div className="flex flex-row items-center justify-between">
        <P>{t.description}</P>
        <button
          className="btn btn-sm"
          onClick={() => {
            event("Accepted Cookie Banner", {
              event_category: "Footer",
              event_label: "",
              value: 0,
            });
            localStorage.setItem("KTZ-has_accepted_cookie_consent", "true");
            setAccepted(true);
          }}
        >
          {t.cta}
        </button>
      </div>
    </div>
  );
};
