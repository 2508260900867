"use client";

import { i18n } from "@/i18n";
import { Link, usePathname } from "@/navigation";
import { useLocale } from "next-intl";

export function LocaleSwitcher() {
  const locale = useLocale();
  const pathname = usePathname();

  return (
    <div className="dropdown dropdown-end dropdown-top">
      <div tabIndex={0} role="button" className="btn m-1 bg-neutral">
        {locale.toUpperCase()}
      </div>
      <ul
        tabIndex={0}
        className="menu dropdown-content z-[1] w-24 bg-neutral p-2 text-base-300"
      >
        {i18n.locales.map((cur) => (
          <li key={cur}>
            <Link
              href={pathname}
              locale={cur}
              className="hover:bg-base-200"
              lang={cur}
            >
              {cur.toUpperCase()}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
