import { useRef } from "react";
import clsx from "clsx";

type ModalProps = {
  children: React.ReactNode;
  handleClose?: () => void;
  withCloseButton?: boolean;
  modalBoxClasses?: string;
};

export function Modal({
  children,
  handleClose,
  withCloseButton = false,
  modalBoxClasses = "",
}: ModalProps) {
  const modalRef = useRef(null);

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === modalRef.current) {
      handleClose?.();
    }
  };

  return (
    <div className="z-50 flex items-center justify-center">
      <div
        ref={modalRef}
        onClick={handleBackdropClick}
        className="modal modal-open"
      >
        <div className={clsx("modal-box rounded-md", modalBoxClasses)}>
          {withCloseButton && (
            <button
              onClick={handleClose}
              className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2 text-base-300"
            >
              ✕
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
