import { AuthenticatedUser, User } from "../types";
import { createClient } from "./contentService/supabase/client";
import MonitoringService from "./monitoringService";

const nullUser: AuthenticatedUser = {
  email: null,
  id: null,
  isAdmin: false,
  isLoggedIn: false,
  isOnboarded: false,
  isPasswordUpdated: false,
  isSubscribed: false,
  profile: {
    childDob: null,
    neighborhood: null,
    stage: "",
    firstName: null,
    lastName: null,
  },
};

export async function getUserMeta(): Promise<User> {
  try {
    const client = createClient();

    // Get the current session
    const {
      data: { session },
      error: sessionError,
    } = await client.auth.getSession();

    if (sessionError) {
      throw new Error(sessionError.message);
    }

    if (!session) {
      // User is not authenticated, return nullUser as fallback
      return nullUser;
    }

    // Fetch the authenticated user
    const {
      data: { user },
      error: userError,
    } = await client.auth.getUser();

    if (userError) {
      MonitoringService.notify(userError);
      return nullUser;
    }

    if (!user || !user.id) {
      return nullUser;
    }

    // Fetch user metadata from 'users_meta' table
    const { data: userData, error: userMetaError } = await client
      .from("users_meta")
      .select(
        "id, email, password_updated, subscription_start, subscription_end, neighborhood, child_dob, stage, first_name, last_name, other_city, is_admin",
      )
      .eq("id", user.id)
      .single();

    if (userMetaError) {
      MonitoringService.notify(new Error(userMetaError.message));
      return nullUser;
    }

    if (!userData) {
      return nullUser; // User metadata not found, return default user
    }

    // Derive subscription status
    const isSubscribed =
      userData.subscription_start &&
      (!userData.subscription_end ||
        new Date(userData.subscription_end) > new Date());

    // Determine if the user is onboarded
    const isOnboarded = !!(
      (!!userData.other_city || !!userData.neighborhood) &&
      userData.child_dob
    );

    MonitoringService.setContext({
      user_id: userData.id,
      email: userData.email,
    });

    // Construct the authenticated user object
    const authenticatedUser: AuthenticatedUser = {
      email: userData.email,
      id: userData.id,
      isAdmin: userData.is_admin,
      isLoggedIn: true,
      isOnboarded,
      isPasswordUpdated: userData.password_updated,
      isSubscribed,
      profile: {
        childDob: userData.child_dob,
        neighborhood: !!userData.other_city
          ? userData.other_city
          : userData.neighborhood,
        stage: userData.stage,
        firstName: userData.first_name,
        lastName: userData.last_name,
      },
    };

    return authenticatedUser;
  } catch (error: any) {
    MonitoringService.notify(new Error(`authenticateUser: ${error.message}`));

    return nullUser;
  }
}
