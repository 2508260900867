import Honeybadger from "@honeybadger-io/js";

Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
});

const MonitoringService = {
  notify: (error: Error, ...rest) => {
    Honeybadger.notify(error, ...rest);
  },
  setContext: (context: Record<string, unknown>) => {
    Honeybadger.setContext(context);
  },
};

export default MonitoringService;
