"use client";

import { Link } from "@/navigation";
import { usePathname } from "@/navigation";

export function NavLink({ slug, name }) {
  const pathname = usePathname();
  const isActive = pathname === slug;

  return (
    <li>
      <Link
        aria-current={isActive ? "page" : undefined}
        href={slug}
        className={isActive ? "text-gray-700" : "text-primary"}
      >
        {name}
      </Link>
    </li>
  );
}

export function NavSideLinkSkeleton() {
  return (
    <li>
      <a href="#">
        Log <span className="text-neutral">Out</span>
      </a>
    </li>
  );
}
