"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

export function RouteListener() {
  const pathname = usePathname();
  const paramsArray = [];
  useSearchParams().forEach((value, key) => {
    paramsArray.push(`${key}=${value}`);
  });
  const searchParams = paramsArray.join("&");

  useEffect(() => {
    const isChecked = document.getElementById("nav-drawer") as HTMLInputElement;
    const aboutSubmenu = document.getElementById("navbar-submenu-about");

    if (aboutSubmenu) {
      aboutSubmenu.removeAttribute("open");
    }

    if (isChecked) {
      isChecked.checked = false;
    }
  }, [pathname, searchParams]);

  return null;
}
