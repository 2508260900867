"use client";
import { logout } from "@/app/actions/logout";
import { useQueryClient } from "@tanstack/react-query";

export default function LogoutButton(props) {
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    await logout();

    // Invalidate the getUserMeta query
    await queryClient.invalidateQueries({ queryKey: ["getUserMeta"] });

    window.location.href = "/login";
  };

  return (
    <button {...props} onClick={handleLogout}>
      Logout
    </button>
  );
}
